import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { useSelector, UseSelector } from "react-redux";
import moment from "moment";

import DownloadGraph from "assets/images/download-graph.svg";

const StackedColumns = ({ chartId, dateRange }: { chartId: string, dateRange: [Date | null, Date | null] }) => {
  const chartColors = useChartColors(chartId);
  const { channels, loading } = useSelector((state: any) => state.ServerSentiment);

  const filteredData = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return channels;

    return channels.map((channel: { data: any[]; }) => ({
      ...channel,
      data: channel.data.filter((item) => {
        const itemDate = new Date(item.Date);
        return itemDate >= dateRange[0]! && itemDate <= dateRange[1]!;
      })
    }));
  }, [channels, dateRange]);

  const combinedData = useMemo(() => {
    if (filteredData.length === 0) return { categories: [], series: [] };

    const dataMap = filteredData.reduce((acc: any, channel: any) => {
      channel.data.forEach((item: any) => {
        const date = item.Date.split('T')[0];
        if (!acc[date]) {
          acc[date] = { Positive: 0, Neutral: 0, Negative: 0 };
        }
        acc[date].Positive += item.Total_Positive;
        acc[date].Neutral += item.Total_Neutral;
        acc[date].Negative += item.Total_Negative;
      });
      return acc;
    }, {});

    const categories = Object.keys(dataMap).sort();
    const series = [
      {
        name: "Negative",
        data: categories.map(date => dataMap[date].Negative)
      },
      {
        name: "Neutral",
        data: categories.map(date => dataMap[date].Neutral)
      },
      {
        name: 'Positive',
        data: categories.map(date => dataMap[date].Positive)
      }
    ];

    return { categories, series };
  }, [filteredData]);

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: combinedData.categories,
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        legend: {
            position: 'top',
        },
        fill: {
            opacity: 1
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={combinedData.series || []}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={800}
            />
        </React.Fragment>
    );
};

const StackedColumns100 = ({ chartId, dateRange }: { chartId: string, dateRange: [Date | null, Date | null] }) => {
    const { channels, loading } = useSelector((state: any) => state.ServerSentiment);

    const combinedData = useMemo(() => {
        if (channels.length === 0) return { categories: [], series: [] };

        const dataMap = channels.reduce((acc: any, channel: any) => {
            channel.data.forEach((item: any) => {
                const date = new Date(item.Date);
                if (dateRange[0] && date < dateRange[0]) return;
                if (dateRange[1] && date > dateRange[1]) return;

                const dateStr = item.Date.split('T')[0];
                if (!acc[dateStr]) {
                    acc[dateStr] = { Positive: 0, Neutral: 0, Negative: 0, Total: 0 };
                }
                acc[dateStr].Positive += item.Total_Positive;
                acc[dateStr].Neutral += item.Total_Neutral;
                acc[dateStr].Negative += item.Total_Negative;
                acc[dateStr].Total += item.Total_Positive + item.Total_Neutral + item.Total_Negative;
            });
            return acc;
        }, {});

        const categories = Object.keys(dataMap).sort();
        const series = [
            {
                name: "Negative",
                data: categories.map(date => ((dataMap[date].Negative / dataMap[date].Total) * 100).toFixed(2) as unknown as number)
            },
            {
                name: "Neutral",
                data: categories.map(date => ((dataMap[date].Neutral / dataMap[date].Total) * 100).toFixed(2) as unknown as number)
            },
            {
                name: 'Positive',
                data: categories.map(date => ((dataMap[date].Positive / dataMap[date].Total) * 100).toFixed(2) as unknown as number)
            }
        ];

        return { categories, series };
    }, [channels, dateRange]);

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: combinedData.categories,
        },
        yaxis: {
            max: 100,
            labels: {
                formatter: function (value: number) {
                    return value.toFixed(0) + '%';
                }
            }
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
        },
        tooltip: {
            y: {
                formatter: function (val: number) {
                    return val.toFixed(2) + '%';
                }
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={combinedData.series || []}
                data-chart-colors='["bg-sky-500", "bg-orange-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={800}
            />
        </React.Fragment>
    );
};

const WeeklyStackedColumn = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
    const { channels, loading } = useSelector((state: any) => state.ServerSentiment);

    let weeklyData: any = {};
    
    if (channels && channels.length > 0) {
        weeklyData = channels.reduce((acc: any, channel: any) => {
            channel.data.forEach((item: any) => {
                const date = moment(item.Date);
                const monthWeek = `${date.format('YYYY-MM')}-${Math.ceil(date.date() / 7)}`;
                if (!acc[monthWeek]) {
                    acc[monthWeek] = { Negative: 0, Neutral: 0, Positive: 0 };
                }
                acc[monthWeek].Negative += item.Total_Negative;
                acc[monthWeek].Neutral += item.Total_Neutral;
                acc[monthWeek].Positive += item.Total_Positive;
            });
            return acc;
        }, {});
    }

    const categories = Object.keys(weeklyData).sort();
    const series = [
        {
            name: "Negative",
            data: categories.map(week => weeklyData[week].Negative)
        },
        {
            name: "Neutral",
            data: categories.map(week => weeklyData[week].Neutral)
        },
        {
            name: 'Positive',
            data: categories.map(week => weeklyData[week].Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(value: any) {
                    if (typeof value !== 'string') {
                        return ''; // or some default value
                    }
                    const parts = value.split('-');
                    if (parts.length !== 3) {
                        return value; // return original value if it doesn't match expected format
                    }
                    const [year, month, week] = parts;
                    return moment(`${year}-${month}`).format('MMMM') + ' Week ' + week;
                }
            }
        },
        colors: ['#3BCDC2', '#D579EF', '#5894FF'],
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

const WeeklyStackedColumn100 = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
    const { channels, loading } = useSelector((state: any) => state.ServerSentiment);

    let weeklyData: any = {};
    
    if (channels && channels.length > 0) {
        weeklyData = channels.reduce((acc: any, channel: any) => {
            channel.data.forEach((item: any) => {
                const date = moment(item.Date);
                const monthWeek = `${date.format('YYYY-MM')}-${Math.ceil(date.date() / 7)}`;
                if (!acc[monthWeek]) {
                    acc[monthWeek] = { Negative: 0, Neutral: 0, Positive: 0 };
                }
                acc[monthWeek].Negative += item.Total_Negative;
                acc[monthWeek].Neutral += item.Total_Neutral;
                acc[monthWeek].Positive += item.Total_Positive;
            });
            return acc;
        }, {});
    }

    const categories = Object.keys(weeklyData).sort();
    const series = [
        {
            name: "Negative",
            data: categories.map(week => weeklyData[week].Negative)
        },
        {
            name: "Neutral",
            data: categories.map(week => weeklyData[week].Neutral)
        },
        {
            name: 'Positive',
            data: categories.map(week => weeklyData[week].Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(value: any) {
                    if (typeof value !== 'string') {
                        return ''; // or some default value
                    }
                    const parts = value.split('-');
                    if (parts.length !== 3) {
                        return value; // return original value if it doesn't match expected format
                    }
                    const [year, month, week] = parts;
                    return moment(`${year}-${month}`).format('MMMM') + ' Week ' + week;
                }
            }
        },
        colors: ['#3BCDC2', '#D579EF', '#5894FF'],
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

const AverageUserPosts = ({ chartId, dateRange }: { chartId: string, dateRange: [Date | null, Date | null] }) => {
  const chartColors = useChartColors(chartId);
  const { channels, Unique_User_Over_Time, loading } = useSelector((state: any) => state.ServerSentiment);

  const filteredData = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return channels;

    return channels.map((channel: { data: any[]; }) => ({
      ...channel,
      data: channel.data.filter((item) => {
        const itemDate = new Date(item.Date);
        return itemDate >= dateRange[0]! && itemDate <= dateRange[1]!;
      })
    }));
  }, [channels, dateRange]);

  const combinedData = useMemo(() => {
    if (filteredData.length === 0) return { categories: [], series: [] };

    const dataMap = filteredData.reduce((acc: any, channel: any) => {
      channel.data.forEach((item: any) => {
        const date = item.Date.split('T')[0];
        if (!acc[date]) {
          acc[date] = { Total_Post: 0, Total_Daily_User: 0 };
        }
        acc[date].Total_Post += item.Total_Post;
        acc[date].Total_Daily_User = item.Total_Daily_User_Today; // Use the value from the channel data
      });
      return acc;
    }, {});

    const categories = Object.keys(dataMap).sort();
    const series = [
      {
        name: "Average User Posts",
        data: categories.map(date => {
          const avgPosts = dataMap[date].Total_Daily_User > 0 
            ? parseFloat((dataMap[date].Total_Post / dataMap[date].Total_Daily_User).toFixed(2))
            : 0;
          return avgPosts;
        })
      },
    ];

    return { categories, series };
  }, [filteredData]);

  var options : any = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: `<img src="${DownloadGraph}" alt="" />`,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      type: 'datetime',
      categories: combinedData.categories,
    },
    colors: ['#FFA500'],
    legend: {
      position: 'top',
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      title: {
        text: 'Average Posts per User'
      }
    },
    tooltip: {
      y: {
        formatter: function(value: number) {
          return value.toFixed(2);
        }
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={combinedData.series || []}
        data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
        id={chartId}
        className="apex-charts"
        type='bar'
        height={350}
      />
    </React.Fragment>
  );
};

export {
    StackedColumns,
    StackedColumns100,
    WeeklyStackedColumn,
    WeeklyStackedColumn100,
    AverageUserPosts,
};