import { createAsyncThunk } from "@reduxjs/toolkit";
import { getChannelSentimentIndividual } from "../../helpers/fakebackend_helper";

export const fetchChannelSentimentIndividual = createAsyncThunk(
    "channelSentimentIndividual/fetchData",
    async (channelId: string, { rejectWithValue }) => {
        try {
            // console.log("Fetching data for channelId:", channelId);
            const response = await getChannelSentimentIndividual(channelId);
            // console.log("Raw API response:", response);

            if (!response || !response.data) {
                throw new Error("Invalid API response structure");
            }

            // Return response.data instead of response.data.data
            return response.data;
        } catch (error) {
            console.error("Error in fetchChannelSentimentIndividual:", error);
            if (error instanceof Error && 'response' in error) {
                const errorResponse = error.response as { data?: unknown };
                if (errorResponse && 'data' in errorResponse) {
                    return rejectWithValue(errorResponse.data);
                }
            }
            return rejectWithValue('An unknown error occurred');
        }
    }
);
