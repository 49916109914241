import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "slices";

const SizesRounded = () => {
    const { channels, loading, error } = useSelector((state: RootState) => state.ServerSentiment);

    return (
        <React.Fragment>
            <div className="card w-1/3 mr-4">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Channel Lists</h6>
                    <div className="flex flex-wrap gap-2">
                        {channels.map((channel) => (
                            <Link
                                key={channel.Channel_Id}
                                to={`/channel/` + channel.Channel_Id}
                                state={{
                                    channelName: channel.Channel_Name,
                                }}
                            >
                                <button
                                    type="button"
                                    className="text-white rounded-full btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                >
                                    {channel.Channel_Name}
                                </button>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SizesRounded;