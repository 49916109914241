import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServerSentimentIndividual } from "../../helpers/fakebackend_helper";

export const fetchServerSentimentIndividual = createAsyncThunk(
    "serverSentimentIndividual/fetchData",
    async (serverId: string, { rejectWithValue }) => {
        try {
            // console.log("Fetching data for serverId:", serverId);
            const response = await getServerSentimentIndividual(serverId);
            // console.log("Raw API response:", response);

            if (!response || !response.data) {
                throw new Error("Invalid API response structure");
            }

            // Return response.data instead of response.data.data
            return response.data;
        } catch (error) {
            console.error("Error in fetchServerSentimentIndividual:", error);
            // ... existing error handling ...
        }
    }
);
