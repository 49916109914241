import { ApexOptions } from 'apexcharts';
import useChartColors from 'Common/useChartColors';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { RootState } from 'slices';

import DownloadGraph from "assets/images/download-graph.svg";

const HourlySentiment = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
    const { data = [] } = useSelector((state: RootState) => state.ChannelSentimentIndividual);

    const series = useMemo(() => {
        const userGroups = (data || []).reduce((acc: any, item: any) => {
            if (!acc[item.User]) {
                acc[item.User] = [];
            }
            acc[item.User].push([new Date(item.Date).getTime(), item.Score]);
            return acc;
        }, {} as Record<string, [number, number][]>);

        return Object.entries(userGroups).map(([name, data]) => ({
            name,
            data,
        }));
    }, [data]);

    const { minScore, maxScore } = useMemo(() => {
        if (!data || data.length === 0) return { minScore: 0, maxScore: 70 };
        const scores = data.map((item: any) => item.Score);
        return {
            minScore: Math.floor(Math.min(...scores)),
            maxScore: Math.ceil(Math.max(...scores))
        };
    }, [data]);

    console.log("Data: ", data);

    const options: ApexOptions = {
        chart: {
            height: 350,
            type: 'scatter',
            toolbar: {
                show: false,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
        },
        xaxis: {
            type: 'datetime',
        },
        colors: ["#58BFFA"],
        yaxis: {
            min: minScore,
            max: maxScore,
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series as ApexAxisChartSeries}
                data-chart-colors='["bg-custom-500","bg-green-500", "bg-yellow-500", "bg-purple-500", "bg-red-500"]'
                id={chartId}
                className="apex-charts"
                height={350}
                type="scatter"
            />
        </React.Fragment>
    );
};

export default HourlySentiment;