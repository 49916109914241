import React from 'react';

const ScrapStatusMessage: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-full">
            <p className="text-center">Scraping in progress, please try again later.</p>
        </div>
    );
};

export default ScrapStatusMessage;
