import React, { useEffect } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import WelcomeWidget from './WelcomeWidget';
import ServerPieChart from './PieChart';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getUserSubscription } from 'slices/user_subscription/thunk';
import ScrapStatusMessage from './ScrapStatusMessage'; // Add this import

const Dashboard = () => {
  const authUser = localStorage.getItem("authUser") as string;
  const userId = `${JSON.parse(authUser)?.user_id}`; // For real data
  // const userId = `280501941338112011`; // For development
  const dispatch = useAppDispatch();
  const { subscriptionData, loading, error } = useAppSelector((state) => state.UserSubscription);
  // console.log("Subscription Data: ", subscriptionData);

  // Add this type guard
  // const isSubscriptionDataArray = Array.isArray(subscriptionData);
  // console.log("array status: ", isSubscriptionDataArray);

  useEffect(() => {
    dispatch(getUserSubscription(userId)); // Replace with userId when deployed, with 280501941338112011 when developing
  }, [dispatch]);

  // console.log("On index:", userId);

  // console.log("Subscription Data: ", subscriptionData); // For debugging purposes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!subscriptionData) return <div>No subscription data available</div>;

  return (
    <React.Fragment>
      <div className='container-fluid group-data-[content=boxed]:max-w-boxed mx-auto'>
        <BreadCrumb title='My Server Sentiment' pageTitle='Dashboards' />
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12">
            <WelcomeWidget />
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              {subscriptionData.data.map((server: any) => (
                server.scrap_status === "pending" ? (
                  <div key={server.server_id} className="card">
                    <div className="card-body">
                      <h6 className="mb-4 text-15">{server.server_name}</h6>
                      <div className="aspect-w-1 aspect-h-1">
                        <ScrapStatusMessage />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link key={server.server_id} to={`/server/${server.server_id}`}>
                    <div className="card">
                      <div className="card-body">
                        <h6 className="mb-4 text-15">{server.server_name}</h6>
                        <div className="aspect-w-1 aspect-h-1">
                          <ServerPieChart serverId={server.server_id} />
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;