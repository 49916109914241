import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getServerSentimentDatas } from "./thunk";

interface ChannelData {
  Channel_Id: string;
  Channel_Name: string;
  Scrap_Status: string;
  Last_Scrapped_At: string;
  Total_Ratio_Channel: number;
  Total_Positive_Channel: number;
  Total_Neutral_Channel: number;
  Total_Negative_Channel: number;
  Total_Posts_Today: number;
  Total_Positive_Today: number;
  Total_Negative_Today: number;
  Total_Posts_Yesterday: number;
  Total_Positive_Yesterday: number;
  Total_Negative_Yesterday: number;
  Difference_Total_Posts_From_Yesterday: number;
  Difference_Positive_Posts_From_Yesterday: number;
  Difference_Negative_Posts_From_Yesterday: number;
  Average_Sentiment_Today: number;
  Posts_Per_User_Today: number;
  Positive_Posts_Today: string;
  Negative_Posts_Today: string;
  Fear_Greed_Index: number;
  Fear_Greed_Gauge: number;
  Difference_From_Yesterday_Index: number;
  data: {
    Date: string;
    Total_Ratio: number;
    Total_Positive: number;
    Total_Neutral: number;
    Total_Negative: number;
    Total_Post: number;
    Mods_Total_Ratio: number;
    Mods_Total_Positive: number;
    Mods_Total_Neutral: number;
    Mods_Total_Negative: number;
    Mods_Total_Post: number;
    Average_User_Post: number;
    Total_Daily_User_Today: number;
    Member_Count: number;
  }[];
}

interface ServerSentimentState {
  Server_Name: string;
  Unique_User_Over_Time: {
    Date: string;
    Total_Daily_User_Server_Today: number;
  }[];
  Total_Posts_Today: number;
  Total_Ratio_Server: number;
  Total_Positive_Server: number;
  Total_Neutral_Server: number;
  Total_Negative_Server: number;
  Average_Sentiment_Today: number;
  Posts_Per_User_Today: number;
  Positive_Posts_Today: string;
  Negative_Posts_Today: string;
  Fear_Greed_Index: number;
  Total_Positive_Today: number;
  Total_Negative_Today: number;
  Total_Posts_Yesterday: number;
  Total_Positive_Yesterday: number;
  Total_Negative_Yesterday: number;
  Difference_Total_Posts_From_Yesterday: number;
  Difference_Positive_Posts_From_Yesterday: number;
  Difference_Negative_Posts_From_Yesterday: number;
  Fear_Greed_Gauge: number;
  Difference_From_Yesterday_Index: number;
  Wordcloud_Image: string;
  Wordcloud_Positive: string;
  Wordcloud_Negative: string;
  channels: ChannelData[];
  loading: boolean;
  error: string | null;
}

export interface ServerSentimentResponse {
  Server_Name: string;
  Unique_User_Over_Time: {
    Date: string;
    Total_Daily_User_Server_Today: number;
  }[];
  Total_Posts_Today: number;
  Total_Ratio_Server: number;
  Total_Positive_Server: number;
  Total_Neutral_Server: number;
  Total_Negative_Server: number;
  Average_Sentiment_Today: number;
  Posts_Per_User_Today: number;
  Positive_Posts_Today: string;
  Negative_Posts_Today: string;
  Fear_Greed_Index: number;
  Total_Positive_Today: number;
  Total_Negative_Today: number;
  Total_Posts_Yesterday: number;
  Total_Positive_Yesterday: number;
  Total_Negative_Yesterday: number;
  Difference_Total_Posts_From_Yesterday: number;
  Difference_Positive_Posts_From_Yesterday: number;
  Difference_Negative_Posts_From_Yesterday: number;
  Fear_Greed_Gauge: number;
  Difference_From_Yesterday_Index: number;
  Wordcloud_Image: string;
  Wordcloud_Positive: string;
  Wordcloud_Negative: string;
  data: ChannelData[];
}

const initialState: ServerSentimentState = {
  Server_Name: "",
  Unique_User_Over_Time: [],
  Total_Posts_Today: 0,
  Total_Ratio_Server: 0,
  Total_Positive_Server: 0,
  Total_Neutral_Server: 0,
  Total_Negative_Server: 0,
  Average_Sentiment_Today: 0,
  Posts_Per_User_Today: 0,
  Positive_Posts_Today: "",
  Negative_Posts_Today: "",
  Fear_Greed_Index: 0,
  Total_Positive_Today: 0,
  Total_Negative_Today: 0,
  Total_Posts_Yesterday: 0,
  Total_Positive_Yesterday: 0,
  Total_Negative_Yesterday: 0,
  Difference_Total_Posts_From_Yesterday: 0,
  Difference_Positive_Posts_From_Yesterday: 0,
  Difference_Negative_Posts_From_Yesterday: 0,
  Fear_Greed_Gauge: 0,
  Difference_From_Yesterday_Index: 0,
  Wordcloud_Image: "",
  Wordcloud_Positive: "",
  Wordcloud_Negative: "",
  channels: [],
  loading: false,
  error: null,
};

const serverSentimentSlice = createSlice({
  name: "serverSentiment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServerSentimentDatas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getServerSentimentDatas.fulfilled, (state, action: PayloadAction<ServerSentimentResponse>) => {
        state.loading = false;
        
        // Function to get the date 60 days ago
        const get60DaysAgo = () => {
          const date = new Date();
          date.setDate(date.getDate() - 60);
          return date;
        };

        const sixtyDaysAgo = get60DaysAgo();

        // Filter Unique_User_Over_Time
        const filteredUniqueUserOverTime = action.payload.Unique_User_Over_Time.filter(item => 
          new Date(item.Date) >= sixtyDaysAgo
        );

        // Filter channel data
        const filteredChannels = action.payload.data.map(channel => ({
          ...channel,
          data: channel.data.filter(item => new Date(item.Date) >= sixtyDaysAgo)
        }));

        // Assign filtered data to state
        Object.assign(state, {
          ...action.payload,
          Unique_User_Over_Time: filteredUniqueUserOverTime,
          channels: filteredChannels
        });
      })
      .addCase(getServerSentimentDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default serverSentimentSlice.reducer;