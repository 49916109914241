import React from "react";
import ReactApexChart from "react-apexcharts";

interface FearGreedGaugeProps {
  // positivePercentage: number;
  // negativePercentage: number;
  Fear_Greed_Index: number;
}

const FearGreedGauge: React.FC<FearGreedGaugeProps> = ({ Fear_Greed_Index }) => {
  // Calculate the display value (positive - negative)
  const displayValue = Fear_Greed_Index;

  // Calculate the display value (0-100 scale)
  const fillValue = (displayValue + 100) / 2;

  var series = [fillValue];
  var options: ApexCharts.ApexOptions = {
    chart: {
      type: 'radialBar',
      offsetY: 0,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#2A3F5B",
          strokeWidth: '97%',
          margin: 1,
          opacity: 0,
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: "325%",
            formatter: function (val) {
              // Add + sign for positive values, - sign for negative values, and no sign for zero
              const sign = displayValue > 0 ? '' : displayValue < 0 ? '-' : '';
              return sign + Math.abs(displayValue).toFixed(2);
            }
          }
        },
        hollow: {
          margin: 15,
          size: "40%"
        },
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#8C0438'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 33, 66, 100],
        colorStops: [
          {
            offset: 0,
            color: "#58BFFA",
            opacity: 1
          },
          {
            offset: 33,
            color: "#726DBD",
            opacity: 1
          },
          {
            offset: 66,
            color: "#DB0F98",
            opacity: 1
          },
          {
            offset: 100,
            color: "#8C0438",
            opacity: 1
          }
        ]
      },
    },
    stroke: {
      dashArray: 4,
      colors: ["#58BFFA"],
      width: 2,
    },
    labels: ['Sentiment'],
    colors: ['#20E647'],
  };

  // New function to get the sentiment label
  const getSentimentLabel = (value: number): string => {
    if (value <= -60) return "Extreme FUD";
    if (value <= -20) return "FUD";
    if (value <= 20) return "Neutral";
    if (value <= 60) return "FOMO";
    return "Extreme FOMO";
  };

  return (
    <div className="flex flex-col items-center justify-center self-center">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={600}
        width={425}
      />
      <div className="w-full mt-6 text-center">
        <span className="text-xl font-bold text-white">
          {getSentimentLabel(displayValue)}
        </span>
      </div>
    </div>
  );
};

export default FearGreedGauge;