import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import Layout from 'Layout';
import NonAuthLayout from "Layout/NonLayout"
import AuthProtected from './AuthProtected';
import { jwtDecode } from "jwt-decode";

interface IJwtPayload {
  user_id: string;
  exp: number;
}

const RouteIndex = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const ref = searchParams.get('ref');
    const channel = searchParams.get('channel');
    const server = searchParams.get('server');

    if (ref) {
      try {
        const decodedJwt = jwtDecode<IJwtPayload>(ref);
        const authUser = {
          email: "admin@themesbrand.com",
          password: "123456",
          role: "admin",
          uid: 1,
          username: "admin",
          user_id: decodedJwt.user_id
        };
        localStorage.setItem('authUser', JSON.stringify(authUser));
        localStorage.setItem('jwt', ref);
        localStorage.setItem('exp', decodedJwt.exp.toString());

        // Redirect based on the presence of channel or server
        if (channel) {
          navigate(`/channel/${channel}`, { replace: true });
        } else if (server) {
          navigate(`/server/${server}`, { replace: true });
        } else {
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.error("Error decoding JWT:", error);
        navigate('/login', { replace: true });
      }
    }
  }, [searchParams, navigate]);

  return (
    <React.Fragment>
      <Routes>
        {authProtectedRoutes.map((route: any, idx: number) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthProtected>
                <Layout>
                  <route.component />
                </Layout>
              </AuthProtected>
            }
          />
        ))}
        {publicRoutes.map((route: any, idx: number) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <NonAuthLayout>
                <route.component />
              </NonAuthLayout>
            } />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default RouteIndex;
