import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWordcloudDatas as getWordcloudDatasApi } from "helpers/fakebackend_helper";

export const getWordcloudDatas = createAsyncThunk(
  "wordcloud/getWordcloudDatas",
  async (channelId: string, { rejectWithValue }) => {
    try {
      const response = await getWordcloudDatasApi(channelId);
      console.log('API Response:', response);
      // Check if the response has the expected structure
      if (response && response.data) {
        return response;
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);