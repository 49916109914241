// src/slices/metric/reducer.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMetricDatas } from "./thunk";

interface MetricData {
  Date: string;
  Total_Posts_Today: number;
  Average_Sentiment_Today: number;
  Posts_Per_User_Today: number;
  Positive_Posts_Today: string;
  Negative_Posts_Today: string;
  Fear_Greed_Index: number;
  Fear_Greed_Gauge: number;
  Difference_From_Yesterday_Index: number;
}

interface MetricState {
  metricData: MetricData[];
  loading: boolean;
  error: string | null;
}

const initialState: MetricState = {
  metricData: [],
  loading: false,
  error: null,
};

const metricSlice = createSlice({
  name: "metric",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetricDatas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMetricDatas.fulfilled, (state, action: PayloadAction<MetricData[]>) => {
        state.loading = false;
        state.metricData = action.payload;
        state.error = null;
      })
      .addCase(getMetricDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default metricSlice.reducer;