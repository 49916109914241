import React, { useMemo, useRef } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";

interface DatePickerProps {
  onDateChange: (dates: Date[]) => void;
  onClear: () => void;
}

const DatePicker: React.FC<DatePickerProps> = ({ onDateChange, onClear }) => {
    const flatpickrRef = useRef<any>(null);

    const maxDate = useMemo(() => new Date(), []);
    const minDate = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() - 60);
        return date;
    }, []);
    
    const handleClear = () => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.clear();
            flatpickrRef.current.flatpickr.setDate(null);
        }
        onClear();
    };

    return (
        <div className="relative">
            <h6 className="text-white text-15 mb-2">Date Range</h6>
            <Flatpickr
                ref={flatpickrRef}
                options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    maxDate: maxDate,
                    minDate: minDate,
                    onChange: (selectedDates) => {
                        if (selectedDates.length === 2) {
                            const [startDate, endDate] = selectedDates;
                            endDate.setHours(23, 59, 59, 999);
                            onDateChange([startDate, endDate]);
                        } else {
                            onDateChange(selectedDates);
                        }
                    },
                }}
                placeholder="Select Date Range"
                className="form-input bg-slate-800 border-slate-700 text-white placeholder-slate-400 focus:border-blue-500 focus:ring-blue-500 rounded-md shadow-sm"
            />
            <button 
                onClick={handleClear}
                className="absolute right-2 top-1.5 transform -translate-y-1/2 text-slate-400 hover:text-white"
            >
                ✕
            </button>
        </div>
    );
}

export default DatePicker;