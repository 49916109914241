import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSentimentData } from "./thunk";

interface SentimentData {
  Date: string;
  Total_Ratio: number;
  Total_Positive: number;
  Total_Neutral: number;
  Total_Negative: number;
  Total_Post: number;
  Mods_Total_Ratio: number;
  Mods_Total_Positive: number;
  Mods_Total_Neutral: number;
  Mods_Total_Negative: number;
  Mods_Total_Post: number;
  Average_User_Post: number;
  Total_Daily_User_Today: number;
  Member_Count: number;
}

interface SentimentResponse {
  Channel_Name: string;
  Scrap_Status: string;
  Last_Scrapped_At: string;
  Total_Ratio_Channel: number;
  Total_Positive_Channel: number;
  Total_Neutral_Channel: number;
  Total_Negative_Channel: number;
  Total_Posts_Channel_Today: number;
  Total_Positive_Channel_Today: number;
  Total_Negative_Channel_Today: number;
  Total_Posts_Channel_Yesterday: number;
  Total_Positive_Channel_Yesterday: number;
  Total_Negative_Channel_Yesterday: number;
  Difference_Total_Posts_From_Yesterday: number;
  Difference_Positive_Posts_From_Yesterday: number;
  Difference_Negative_Posts_From_Yesterday: number;
  Fear_Greed_Gauge: number;
  data: SentimentData[];
}

interface SentimentState {
  channelName: string;
  scrapStatus: string;
  lastScrappedAt: string;
  totalRatioChannel: number;
  totalPositiveChannel: number;
  totalNeutralChannel: number;
  totalNegativeChannel: number;
  totalPostsChannelToday: number;
  totalPositiveChannelToday: number;
  totalNegativeChannelToday: number;
  totalPostsChannelYesterday: number;
  totalPositiveChannelYesterday: number;
  totalNegativeChannelYesterday: number;
  differenceTotalPostsFromYesterday: number;
  differencePositivePostsFromYesterday: number;
  differenceNegativePostsFromYesterday: number;
  fearGreedGauge: number;
  sentimentData: SentimentData[];
  loading: boolean;
  error: string | null;
}

const initialState: SentimentState = {
  channelName: "",
  scrapStatus: "",
  lastScrappedAt: "",
  totalRatioChannel: 0,
  totalPositiveChannel: 0,
  totalNeutralChannel: 0,
  totalNegativeChannel: 0,
  totalPostsChannelToday: 0,
  totalPositiveChannelToday: 0,
  totalNegativeChannelToday: 0,
  totalPostsChannelYesterday: 0,
  totalPositiveChannelYesterday: 0,
  totalNegativeChannelYesterday: 0,
  differenceTotalPostsFromYesterday: 0,
  differencePositivePostsFromYesterday: 0,
  differenceNegativePostsFromYesterday: 0,
  fearGreedGauge: 0,
  sentimentData: [],
  loading: false,
  error: null,
};

const SentimentSlice = createSlice({
  name: "sentiment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSentimentData.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log('Fetching sentiment data...');
      })
      .addCase(getSentimentData.fulfilled, (state, action: PayloadAction<SentimentResponse>) => {
        console.log('Received sentiment data:', action.payload);
        state.loading = false;
        state.channelName = action.payload.Channel_Name;
        state.scrapStatus = action.payload.Scrap_Status;
        state.lastScrappedAt = action.payload.Last_Scrapped_At;
        state.totalRatioChannel = action.payload.Total_Ratio_Channel;
        state.totalPositiveChannel = action.payload.Total_Positive_Channel;
        state.totalNeutralChannel = action.payload.Total_Neutral_Channel;
        state.totalNegativeChannel = action.payload.Total_Negative_Channel;
        state.totalPostsChannelToday = action.payload.Total_Posts_Channel_Today;
        state.totalPositiveChannelToday = action.payload.Total_Positive_Channel_Today;
        state.totalNegativeChannelToday = action.payload.Total_Negative_Channel_Today;
        state.totalPostsChannelYesterday = action.payload.Total_Posts_Channel_Yesterday;
        state.totalPositiveChannelYesterday = action.payload.Total_Positive_Channel_Yesterday;
        state.totalNegativeChannelYesterday = action.payload.Total_Negative_Channel_Yesterday;
        state.differenceTotalPostsFromYesterday = action.payload.Difference_Total_Posts_From_Yesterday;
        state.differencePositivePostsFromYesterday = action.payload.Difference_Positive_Posts_From_Yesterday;
        state.differenceNegativePostsFromYesterday = action.payload.Difference_Negative_Posts_From_Yesterday;
        state.fearGreedGauge = action.payload.Fear_Greed_Gauge;
        state.sentimentData = action.payload.data.slice(-60);
        state.error = null;
      })
      .addCase(getSentimentData.rejected, (state, action) => {
        console.log('Error fetching sentiment data:', action.error);
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default SentimentSlice.reducer;