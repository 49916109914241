import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSentimentData as getSentimentDataApi } from "../../helpers/fakebackend_helper";

interface SentimentData {
  Date: string;
  Total_Ratio: number;
  Total_Positive: number;
  Total_Neutral: number;
  Total_Negative: number;
  Total_Post: number;
  Mods_Total_Ratio: number;
  Mods_Total_Positive: number;
  Mods_Total_Neutral: number;
  Mods_Total_Negative: number;
  Mods_Total_Post: number;
  Average_User_Post: number;
  Total_Daily_User_Today: number;
  Member_Count: number;
}

interface SentimentResponse {
  Channel_Name: string;
  Scrap_Status: string;
  Last_Scrapped_At: string;
  Total_Ratio_Channel: number;
  Total_Positive_Channel: number;
  Total_Neutral_Channel: number;
  Total_Negative_Channel: number;
  Total_Posts_Channel_Today: number;
  Total_Positive_Channel_Today: number;
  Total_Negative_Channel_Today: number;
  Total_Posts_Channel_Yesterday: number;
  Total_Positive_Channel_Yesterday: number;
  Total_Negative_Channel_Yesterday: number;
  Difference_Total_Posts_From_Yesterday: number;
  Difference_Positive_Posts_From_Yesterday: number;
  Difference_Negative_Posts_From_Yesterday: number;
  Fear_Greed_Gauge: number;
  data: SentimentData[];
}

export const getSentimentData = createAsyncThunk<SentimentResponse, string>(
  "sentiment/getSentimentData",
  async (channelId: string, { rejectWithValue }) => {
    try {
      const response = await getSentimentDataApi(channelId);
      console.log('API Response:', response);

      // Validate the response structure
      if (!response || typeof response !== 'object' || !('data' in response)) {
        throw new Error('Invalid response structure');
      }

      // Ensure all required fields are present
      const requiredFields = [
        'Channel_Name', 'Scrap_Status', 'Last_Scrapped_At',
        'Total_Ratio_Channel', 'Total_Positive_Channel',
        'Total_Neutral_Channel', 'Total_Negative_Channel',
        'Total_Posts_Channel_Today', 'Total_Positive_Channel_Today',
        'Total_Negative_Channel_Today', 'Total_Posts_Channel_Yesterday',
        'Total_Positive_Channel_Yesterday', 'Total_Negative_Channel_Yesterday',
        'Difference_Total_Posts_From_Yesterday',
        'Difference_Positive_Posts_From_Yesterday',
        'Difference_Negative_Posts_From_Yesterday',
        'Fear_Greed_Gauge', 'data'
      ];

      for (const field of requiredFields) {
        if (!(field in response)) {
          throw new Error(`Missing required field: ${field}`);
        }
      }

      return response as unknown as SentimentResponse;
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);