import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServerSentimentDatas as getServerSentimentDatasApi } from "../../helpers/fakebackend_helper";
import { ServerSentimentResponse } from "./reducer";

export const getServerSentimentDatas = createAsyncThunk<ServerSentimentResponse, string>(
  "sentiment/getServerSentimentDatas",
  async (serverId: string, { rejectWithValue }) => {
    try {
      const response = await getServerSentimentDatasApi(serverId) as unknown;
      console.log('API Response:', response);
      
      // Validate the response structure
      if (!response || typeof response !== 'object') {
        throw new Error('Invalid response structure');
      }
      
      // Ensure all required fields are present
      const requiredFields = [
        'Server_Name', 'Unique_User_Over_Time', 'Total_Posts_Today', 'Total_Ratio_Server',
        'Total_Positive_Server', 'Total_Neutral_Server', 'Total_Negative_Server',
        'Average_Sentiment_Today', 'Posts_Per_User_Today', 'Positive_Posts_Today',
        'Negative_Posts_Today', 'Fear_Greed_Index', 'Total_Positive_Today',
        'Total_Negative_Today', 'Total_Posts_Yesterday', 'Total_Positive_Yesterday',
        'Total_Negative_Yesterday', 'Difference_Total_Posts_From_Yesterday',
        'Difference_Positive_Posts_From_Yesterday', 'Difference_Negative_Posts_From_Yesterday',
        'Fear_Greed_Gauge', 'Difference_From_Yesterday_Index', 'Wordcloud_Image',
        'Wordcloud_Positive', 'Wordcloud_Negative', 'data'
      ];

      for (const field of requiredFields) {
        if (!(field in response)) {
          throw new Error(`Error in Server/Channel ID, please check your server details in your Profile page`);
        }
      }

      return response as ServerSentimentResponse;
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);