import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { useSelector } from 'react-redux';

import DownloadGraph from 'assets/images/download-graph.svg';

interface ChartProps {
    chartId: string;
    data: any[];
    dateRange: [Date | null, Date | null];
}

const PositiveChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) return data;
        return data.filter((item) => {
            const itemDate = new Date(item.Date);
            console.log("Item date:", itemDate);
            return itemDate >= dateRange[0]! && itemDate < dateRange[1]!;
        });
    }, [data, dateRange]);

    const categories = filteredData.map((item) => item.Date);
    const series: any = [
        {
            name: 'Negative',
            data: filteredData.map((item) => item.Total_Negative)
        },
        {
            name: 'Neutral',
            data: filteredData.map((item) => item.Total_Neutral)
        },
        {
            name: 'Positive',
            data: filteredData.map((item) => item.Total_Positive)
        },
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'center'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={800}
            />
        </React.Fragment>
    );
};

const NegativeChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const { sentimentData } = useSelector((state: any) => state.Sentiment);
    const categories = sentimentData.map((data: any) => data.Date);
    const series: any = [
        {
            name: 'Negative',
            data: data.map((item: any) => item.Total_Negative)
        }
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },

        title: {
            text: 'Negative',
            align: 'left'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#ED346B'],
        xaxis: {
            type: 'datetime',
            categories: categories,
            min: dateRange[0]?.getTime(),
            max: dateRange[1]?.getTime(),
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={250}
            />
        </React.Fragment>
    );
};

const NeutralChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const { sentimentData } = useSelector((state: any) => state.Sentiment);
    const categories = sentimentData.map((data: any) => data.Date);
    const series: any = [
        {
            name: 'Neutral',
            data: data.map((data: any) => data.Total_Neutral)
        }
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },

        title: {
            text: 'Neutral',
            align: 'left'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#EB00FF'],
        xaxis: {
            type: 'datetime',
            categories: categories,
            min: dateRange[0]?.getTime(),
            max: dateRange[1]?.getTime(),
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={250}
            />
        </React.Fragment>
    );
};

const TotalDailyPostChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) return data;
        return data.filter((item) => {
            const itemDate = new Date(item.Date);
            console.log("Item date:", itemDate);
            return itemDate >= dateRange[0]! && itemDate < dateRange[1]!;
        });
    }, [data, dateRange]);

    const categories = filteredData.map((item) => item.Date);
    const series: any = [
        {
            name: 'Total Daily Posts',
            data: data.map((item: any) => item.Total_Post)
        }
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#ED346B'],
        xaxis: {
            type: 'datetime',
            categories: categories,
            min: dateRange[0]?.getTime(),
            max: dateRange[1]?.getTime(),
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={350}
            />
        </React.Fragment>
    );
};

const TotalDailyUserChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) return data;
        return data.filter((item) => {
            const itemDate = new Date(item.Date);
            console.log("Item date:", itemDate);
            return itemDate >= dateRange[0]! && itemDate < dateRange[1]!;
        });
    }, [data, dateRange]);

    const categories = filteredData.map((item) => item.Date);

    const series: any = [
        {
            name: 'Total Daily User',
            data: filteredData.map((item: any) => item.Total_Daily_User_Today)
        }
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#FFD700'],
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={350}
            />
        </React.Fragment>
    );
};

const ModsTotalPostChart: React.FC<ChartProps> = ({ chartId, data, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) return data;
        return data.filter((item) => {
            const itemDate = new Date(item.Date);
            console.log("Item date:", itemDate);
            return itemDate >= dateRange[0]! && itemDate < dateRange[1]!;
        });
    }, [data, dateRange]);

    const categories = filteredData.map((item) => item.Date);
    const series: any = [
        {
            name: 'Mods Total Posts',
            data: filteredData.map((item: any) => item.Mods_Total_Post)
        }
    ];

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#32CD32'],
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        yaxis: {
            opposite: true
        },
        legend: {
            horizontalAlign: 'left'
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-custom-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={350}
            />
        </React.Fragment>
    );
};

export {
    PositiveChart,
    NegativeChart,
    NeutralChart,
    TotalDailyPostChart,
    TotalDailyUserChart,
    ModsTotalPostChart,
};