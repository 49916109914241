import useChartColors from "Common/useChartColors";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import moment from "moment";

import DownloadGraph from "assets/images/download-graph.svg";

const ServerLineOverview = ({ dateRange, chartId }: { dateRange: [Date | null, Date | null], chartId?: string }) => {
    const { channels, loading } = useSelector((state: any) => state.ServerSentiment);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) return channels;

        return channels.map((channel: { data: any[]; }) => ({
            ...channel,
            data: channel.data.filter((item: any) => {
                const itemDate = new Date(item.Date);
                return itemDate >= dateRange[0]! && itemDate <= dateRange[1]!;
            })
        }));
    }, [channels, dateRange]);

    const combinedData = useMemo(() => {
        if (filteredData.length === 0) return { categories: [], series: [] };

        const dataMap = new Map();

        filteredData.forEach((channel: { data: any[]; }) => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                if (!dataMap.has(date)) {
                    dataMap.set(date, { Positive: 0, Neutral: 0, Negative: 0 });
                }
                const currentData = dataMap.get(date);
                currentData.Positive += item.Total_Positive;
                currentData.Neutral += item.Total_Neutral;
                currentData.Negative += item.Total_Negative;
            });
        });

        const categories = Array.from(dataMap.keys()).sort();
        const series = [
            {
                name: "Negative",
                data: categories.map(date => dataMap.get(date).Negative)
            },
            {
                name: "Neutral",
                data: categories.map(date => dataMap.get(date).Neutral)
            },
            {
                name: 'Positive',
                data: categories.map(date => dataMap.get(date).Positive)
            }
        ];

        return { categories, series };
    }, [filteredData]);

    var options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        legend: {
            tooltipHoverFormatter: function (val: any, opts: any) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            },
            position: 'top',
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        markers: {
            size: 0,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            type: 'datetime',
            categories: combinedData.categories,
        },
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val: any) {
                            return val + " (count)"
                        }
                    }
                },
                {
                    title: {
                        formatter: function (val: any) {
                            return val + " (count)"
                        }
                    }
                },
                {
                    title: {
                        formatter: function (val: any) {
                            return val + " (count)"
                        }
                    }
                }
            ]
        },
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={combinedData.series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500"]'
                id={chartId}
                className="apex-charts"
                type='area'
                height={800}
            />
        </React.Fragment>
    );
}

export default ServerLineOverview;