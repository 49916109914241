import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { useSelector } from 'react-redux';
import moment from 'moment';

import DownloadGraph from 'assets/images/download-graph.svg';

interface ColumnChartProps {
  chartId: string;
  dateRange: [Date | null, Date | null];
}

const StackedColumns: React.FC<ColumnChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const { sentimentData } = useSelector((state: any) => state.Sentiment);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) {
            // If no date range is selected, return the last 60 days of data
            const sixtyDaysAgo = new Date();
            sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
            return sentimentData.filter((data: any) => new Date(data.Date) >= sixtyDaysAgo);
        }
        return sentimentData.filter((data: any) => {
            const dataDate = new Date(data.Date);
            return dataDate >= dateRange[0]! && dataDate <= dateRange[1]!;
        });
    }, [sentimentData, dateRange]);

    const categories = filteredData.map((data: any) => data.Date);
    const series = [
        {
            name: "Negative",
            data: filteredData.map((data: any) => data.Total_Negative)
        },
        {
            name: "Neutral",
            data: filteredData.map((data: any) => data.Total_Neutral)
        },
        {
            name: 'Positive',
            data: filteredData.map((data: any) => data.Total_Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={800}
            />
        </React.Fragment>
    );
};

const StackedColumns100: React.FC<ColumnChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const { sentimentData } = useSelector((state: any) => state.Sentiment);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) {
            // If no date range is selected, return the last 60 days of data
            const sixtyDaysAgo = new Date();
            sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
            return sentimentData.filter((data: any) => new Date(data.Date) >= sixtyDaysAgo);
        }
        return sentimentData.filter((data: any) => {
            const dataDate = new Date(data.Date);
            return dataDate >= dateRange[0]! && dataDate <= dateRange[1]!;
        });
    }, [sentimentData, dateRange]);

    const categories = filteredData.map((data: any) => data.Date);
    const series = [
        {
            name: "Negative",
            data: filteredData.map((data: any) => data.Total_Negative)
        },
        {
            name: "Neutral",
            data: filteredData.map((data: any) => data.Total_Neutral)
        },
        {
            name: 'Positive',
            data: filteredData.map((data: any) => data.Total_Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        colors: ['#D579EF', '#5894FF', '#3BCDC2',],
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
        },
    };
    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                data-chart-colors='["bg-sky-500", "bg-orange-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={800}
            />
        </React.Fragment>
    );
};

const WeeklyStackedColumn = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
    const { sentimentData } = useSelector((state: any) => state.Sentiment);

    // Group data by week
    const weeklyData = sentimentData.reduce((acc: any, data: any) => {
        const date = moment(data.Date);
        const monthWeek = `${date.format('YYYY-MM')}-${Math.ceil(date.date() / 7)}`;
        if (!acc[monthWeek]) {
            acc[monthWeek] = { Negative: 0, Neutral: 0, Positive: 0 };
        }
        acc[monthWeek].Negative += data.Total_Negative;
        acc[monthWeek].Neutral += data.Total_Neutral;
        acc[monthWeek].Positive += data.Total_Positive;
        return acc;
    }, {});

    const categories = Object.keys(weeklyData).sort();
    const series = [
        {
            name: "Negative",
            data: categories.map(week => weeklyData[week].Negative)
        },
        {
            name: "Neutral",
            data: categories.map(week => weeklyData[week].Neutral)
        },
        {
            name: 'Positive',
            data: categories.map(week => weeklyData[week].Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(value: any) {
                    if (typeof value !== 'string') {
                        return ''; // or some default value
                    }
                    const parts = value.split('-');
                    if (parts.length !== 3) {
                        return value; // return original value if it doesn't match expected format
                    }
                    const [year, month, week] = parts;
                    return moment(`${year}-${month}`).format('MMMM') + ' Week ' + week;
                }
            }
        },
        colors: ['#3BCDC2', '#D579EF', '#5894FF'],
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

const WeeklyStackedColumn100 = ({ chartId }: any) => {
    const chartColors = useChartColors(chartId);
    const { sentimentData } = useSelector((state: any) => state.Sentiment);

    // Group data by week
    const weeklyData = sentimentData.reduce((acc: any, data: any) => {
        const date = moment(data.Date);
        const monthWeek = `${date.format('YYYY-MM')}-${Math.ceil(date.date() / 7)}`;
        if (!acc[monthWeek]) {
            acc[monthWeek] = { Negative: 0, Neutral: 0, Positive: 0 };
        }
        acc[monthWeek].Negative += data.Total_Negative;
        acc[monthWeek].Neutral += data.Total_Neutral;
        acc[monthWeek].Positive += data.Total_Positive;
        return acc;
    }, {});

    const categories = Object.keys(weeklyData).sort();
    const series = [
        {
            name: "Negative",
            data: categories.map(week => weeklyData[week].Negative)
        },
        {
            name: "Neutral",
            data: categories.map(week => weeklyData[week].Neutral)
        },
        {
            name: 'Positive',
            data: categories.map(week => weeklyData[week].Positive)
        }
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%'
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(value: any) {
                    if (typeof value !== 'string') {
                        return ''; // or some default value
                    }
                    const parts = value.split('-');
                    if (parts.length !== 3) {
                        return value; // return original value if it doesn't match expected format
                    }
                    const [year, month, week] = parts;
                    return moment(`${year}-${month}`).format('MMMM') + ' Week ' + week;
                }
            }
        },
        colors: ['#3BCDC2', '#D579EF', '#5894FF'],
        fill: {
            opacity: 1
        },
        legend: {
            position: 'bottom',
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

const AverageUserPosts: React.FC<ColumnChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const { sentimentData } = useSelector((state: any) => state.Sentiment);

    const filteredData = useMemo(() => {
        if (!dateRange[0] || !dateRange[1]) {
            // If no date range is selected, return the last 60 days of data
            const sixtyDaysAgo = new Date();
            sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
            return sentimentData.filter((data: any) => new Date(data.Date) >= sixtyDaysAgo);
        }
        return sentimentData.filter((data: any) => {
            const dataDate = new Date(data.Date);
            return dataDate >= dateRange[0]! && dataDate <= dateRange[1]!;
        });
    }, [sentimentData, dateRange]);

    const categories = filteredData.map((data: any) => data.Date);
    const series = [
        {
            name: "Average Posts per User",
            data: filteredData.map((data: any) => data.Average_User_Post.toFixed(2))
        },
    ];

    var options : any = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: false,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'datetime',
            categories: categories,
        },
        colors: ['#FFA500'],
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series}
                data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-yellow-500"]'
                id={chartId}
                className="apex-charts"
                type='bar'
                height={350}
            />
        </React.Fragment>
    );
};

export {
    StackedColumns,
    StackedColumns100,
    WeeklyStackedColumn,
    WeeklyStackedColumn100,
    AverageUserPosts,
};