import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getWordcloudDatas } from "./thunk";

interface WordcloudData {
  wordcloud_image: string | null;
  wordcloud_image_date: string | null;
  wordcloud_positive: string | null;
  wordcloud_positive_date: string | null;
  wordcloud_negative: string | null;
  wordcloud_negative_date: string | null;
}

interface WordcloudState {
  data: WordcloudData;
  loading: boolean;
  error: string | null;
}

const initialState: WordcloudState = {
  data: {
    wordcloud_image: null,
    wordcloud_image_date: null,
    wordcloud_positive: null,
    wordcloud_positive_date: null,
    wordcloud_negative: null,
    wordcloud_negative_date: null,
  },
  loading: false,
  error: null,
};

const wordcloudSlice = createSlice({
  name: "wordcloud",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWordcloudDatas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWordcloudDatas.fulfilled, (state, action: PayloadAction<{ data: WordcloudData }>) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(getWordcloudDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.data = initialState.data;
      });
  },
});

export default wordcloudSlice.reducer;