// src/slices/metric/thunk.ts

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMetricDatas as getMetricDatasApi } from "../../helpers/fakebackend_helper";

export const getMetricDatas = createAsyncThunk(
  "metric/getMetricDatas",
  async (channelId: string, { rejectWithValue }) => {
    try {
      const response = await getMetricDatasApi(channelId);
      console.log('API Response:', response);
      // Check the structure of the response and return the data accordingly
      return response.data ? response.data : response;
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);