import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserSubscription as getUserSubscriptionApi } from "../../helpers/fakebackend_helper";

export const getUserSubscription = createAsyncThunk(
  "userSubscription/getUserSubscription",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await getUserSubscriptionApi();
      console.log('API Response:', response);
      // The API now returns { data: { user_id: string, data: [...] } }
      return response.data ? response.data : response;
    } catch (error: any) {
      console.error('API Error:', error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);