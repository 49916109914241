import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useAppDispatch } from "hooks";
import { getServerSentimentDatas } from "slices/server_sentiment/thunk";

interface ServerPieChartProps {
  serverId: string;
}

const ServerPieChart: React.FC<ServerPieChartProps> = ({ serverId }) => {
  const dispatch = useAppDispatch();
  const [sentimentData, setSentimentData] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    dispatch(getServerSentimentDatas(serverId))
      .unwrap()
      .then((result) => {
        setSentimentData([
          result.Total_Negative_Server,
          result.Total_Neutral_Server,
          result.Total_Positive_Server,
        ]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [dispatch, serverId]);

  // console.log("On Pie Chart:", userId);

  // console.log("Total Negative Server: ", Total_Negative_Server); // For debugging purposes
  // console.log("Total Neutral Server: ", Total_Neutral_Server);  // For debugging purposes
  // console.log("Total Positive Server: ", Total_Positive_Server);  // For debugging purposes

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const series = sentimentData;

  var options: any = {
    chart: {
      height: 350,
      type: "donut",
    },
    labels: ['Negative', 'Neutral', 'Positive'],
    colors: ['#3BCDC2', '#D579EF', '#5894FF'],
    legend: {
      position: 'bottom'
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series || []}
        data-chart-colors='["bg-custom-500", "bg-orange-500", "bg-green-500", "bg-sky-500", "bg-yellow-500"]'
        className="apex-charts"
        height={350}
        type="donut"
      />
    </React.Fragment>
  );
};

export default ServerPieChart;