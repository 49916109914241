import React, { ReactNode, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

interface AuthProtectedProps {
  children: ReactNode;
}

interface IJwtPayload {
  user_id: string;
  exp: number;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const ref = searchParams.get("ref");
    const channel = searchParams.get("channel");
    const server = searchParams.get("server");

    console.log("Ref:", ref);
    console.log("Channel:", channel);
    console.log("Server:", server);

    if (ref) {
      try {
        const decodedJwt = jwtDecode<IJwtPayload>(ref);
        console.log("Decoded JWT:", decodedJwt);

        const token = ref;
        const authUser = JSON.stringify({
          user_id: decodedJwt.user_id,
        });

        localStorage.setItem("jwt", token);
        localStorage.setItem("authUser", authUser);

        setIsAuthenticated(true);

        console.log("Authentication successful");

        if (channel) {
          console.log("Redirecting to channel:", channel);
          navigate(`/channel/${channel}`, { replace: true });
        } else if (server) {
          console.log("Redirecting to server:", server);
          navigate(`/server/${server}`, { replace: true });
        } else {
          console.log("Redirecting to dashboard");
          navigate("/dashboard", { replace: true });
        }
      } catch (error) {
        console.error("Error decoding JWT:", error);
        setIsAuthenticated(false);
      }
    } else {
      const storedToken = localStorage.getItem("jwt");
      const storedAuthUser = localStorage.getItem("authUser");

      console.log("Stored Token:", storedToken ? "exists" : "not found");
      console.log("Stored Auth User:", storedAuthUser ? "exists" : "not found");

      if (!storedAuthUser && !storedToken) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    }
  }, [searchParams, navigate]);

  console.log("Is Authenticated:", isAuthenticated);

  if (isAuthenticated === null) return <div>Loading...</div>;

  if (isAuthenticated === false) {
    console.log("Redirecting to login page");
    window.location.href =
      (process.env.REACT_APP_MAIN_DASHBOARD_URL as string) + "login";
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
