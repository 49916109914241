import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface ScrapChannel {
  channel_id: string;
  date_subscribed_end: string;
  expired: boolean;
}

interface SubscriptionData {
  server_id: string;
  scrap_channel_id: ScrapChannel[];
}

interface SubscriptionState {
  subscriptions: SubscriptionData[];
  loading: boolean;
  error: string | null;
}

const initialState: SubscriptionState = {
  subscriptions: [],
  loading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    fetchSubscriptionsRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSubscriptionsSuccess(state, action: PayloadAction<SubscriptionData[]>) {
      state.loading = false;
      state.subscriptions = action.payload;
    },
    fetchSubscriptionsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSubscriptionsRequest,
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
} = subscriptionSlice.actions;

export const selectSubscriptions = (state: RootState) => state.Subscription.subscriptions;
export const selectLoading = (state: RootState) => state.Subscription.loading;
export const selectError = (state: RootState) => state.Subscription.error;

export default subscriptionSlice.reducer;
