import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "Common/useChartColors";
import { useSelector } from 'react-redux';

import DownloadGraph from "assets/images/download-graph.svg";
import { useAppSelector } from "hooks";
import { ApexOptions } from "apexcharts";

interface ChartProps {
    chartId: string;
    dateRange: [Date | null, Date | null];
}

const filterDataByDateRange = (data: any[], dateRange: [Date | null, Date | null]) => {
    if (!dateRange[0] || !dateRange[1]) return data;

    return data.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= dateRange[0]! && itemDate <= dateRange[1]!;
    });
};

const PositiveChart: React.FC<ChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const serverSentiment = useAppSelector((state) => state.ServerSentiment);

    const aggregatedData = useMemo(() => {
        const dataMap = new Map();
        serverSentiment.channels.forEach(channel => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                if (!dataMap.has(date)) {
                    dataMap.set(date, { Positive: 0, Negative: 0, Neutral: 0 });
                }
                const data = dataMap.get(date);
                data.Positive += item.Total_Positive;
                data.Negative += item.Total_Negative;
                data.Neutral += item.Total_Neutral;
            });
        });
        return Array.from(dataMap, ([date, values]) => ({ date, ...values }));
    }, [serverSentiment.channels]);

    const filteredData = filterDataByDateRange(aggregatedData, dateRange);

    const series = [
        { name: 'Positive', data: filteredData.map(item => item.Positive) },
        { name: 'Negative', data: filteredData.map(item => item.Negative) },
        { name: 'Neutral', data: filteredData.map(item => item.Neutral) },
    ];

    const options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        grid: { padding: { top: 0, right: 0, bottom: 0, left: 0 } },
        colors: ['#3BCDC2', '#ED346B', '#EB00FF'],
        xaxis: {
            type: 'datetime',
            categories: filteredData.map(item => item.date),
            min: dateRange[0]?.getTime(),
            max: dateRange[1]?.getTime(),
        },
        yaxis: { opposite: true },
        legend: { horizontalAlign: 'center' }
    };

    return (
        <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type='area'
            height={750}
        />
    );
};

const TotalDailyPostChart: React.FC<ChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const serverSentiment = useAppSelector((state) => state.ServerSentiment);

    const aggregatedData = useMemo(() => {
        const dataMap = new Map();
        const sortedDates = new Set();

        // Collect all dates
        serverSentiment.channels.forEach(channel => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                sortedDates.add(date);
            });
        });

        // Sort dates
        const allDates = Array.from(sortedDates).sort();

        // Initialize all dates with null
        allDates.forEach(date => dataMap.set(date, null));

        // Fill in actual data
        serverSentiment.channels.forEach(channel => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                if (dataMap.get(date) === null) {
                    dataMap.set(date, item.Total_Post);
                } else {
                    dataMap.set(date, (dataMap.get(date) || 0) + item.Total_Post);
                }
            });
        });

        return Array.from(dataMap, ([date, total]) => ({ date, total }));
    }, [serverSentiment.channels]);

    const filteredData = filterDataByDateRange(aggregatedData, dateRange);

    const series = [
        { name: 'Total Daily Posts', data: filteredData.map(item => item.total) }
    ];

    const options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        grid: { padding: { top: 0, right: 0, bottom: 0, left: 0 } },
        colors: ['#ED346B'],
        xaxis: {
            type: 'datetime',
            categories: filteredData.map(item => item.date),
        },
        yaxis: { opposite: true },
        legend: { horizontalAlign: 'left' }
    };

    return (
        <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type='area'
            height={350}
        />
    );
};

const TotalDailyUserChart: React.FC<ChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);
    const serverSentiment = useAppSelector((state) => state.ServerSentiment);

    const aggregatedData = useMemo(() => {
        return serverSentiment.Unique_User_Over_Time.map(item => ({
            date: item.Date.split('T')[0],
            total: item.Total_Daily_User_Server_Today
        }));
    }, [serverSentiment.Unique_User_Over_Time]);

    const filteredData = filterDataByDateRange(aggregatedData, dateRange);

    const series = [
        { name: 'Total Daily Users', data: filteredData.map(item => item.total) }
    ];

    const options: any = {
        chart: {
            type: 'area',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        grid: { padding: { top: 0, right: 0, bottom: 0, left: 0 } },
        colors: ['#FFD700'],
        xaxis: {
            type: 'datetime',
            categories: filteredData.map(item => item.date),
        },
        yaxis: { opposite: true },
        legend: { horizontalAlign: 'left' }
    };

    return (
        <ReactApexChart
            dir="ltr"
            options={options}
            series={series}
            type='area'
            height={350}
        />
    );
};

const ModsTotalPostChart: React.FC<ChartProps> = ({ chartId, dateRange }) => {
    const chartColors = useChartColors(chartId);

    const serverSentiment = useAppSelector((state) => state.ServerSentiment);

    const aggregatedData = useMemo(() => {
        const dataMap = new Map();
        const sortedDates = new Set();

        // Collect all dates
        serverSentiment.channels.forEach(channel => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                sortedDates.add(date);
            });
        });

        // Sort dates
        const allDates = Array.from(sortedDates).sort();

        // Initialize all dates with null
        allDates.forEach(date => dataMap.set(date, null));

        // Fill in actual data
        serverSentiment.channels.forEach(channel => {
            channel.data.forEach(item => {
                const date = item.Date.split('T')[0];
                if (dataMap.get(date) === null) {
                    dataMap.set(date, item.Mods_Total_Post);
                } else {
                    dataMap.set(date, (dataMap.get(date) || 0) + item.Mods_Total_Post);
                }
            });
        });

        return Array.from(dataMap, ([date, total]) => ({ date, total }));
    }, [serverSentiment.channels]);

    const filteredData = filterDataByDateRange(aggregatedData, dateRange);

    const series = [
        { name: 'Mods Total Posts', data: filteredData.map(item => item.total) }
    ];

    var options: ApexOptions = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: {
                show: true,
                tools: {
                    download: `<img src="${DownloadGraph}" alt="" />`,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: []
                },
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
        },
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        colors: ['#32CD32'],
        xaxis: {
            type: 'datetime',
            categories: filteredData.map(item => item.date),
        },
        yaxis: {
            opposite: true,
        },
        legend: {
            horizontalAlign: 'left',
        },
        fill: {
            type: 'solid',
            opacity: 0.3
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart
                dir="ltr"
                options={options}
                series={series || []}
                id={chartId}
                className="apex-charts"
                type='area'
                height={350}
            />
        </React.Fragment>
    );
};

export {
    PositiveChart,
    TotalDailyPostChart,
    TotalDailyUserChart,
    ModsTotalPostChart,
};