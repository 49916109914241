import ReactApexChart from "react-apexcharts";
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ApexOptions } from "apexcharts";

// New function to calculate last 2 weeks data
const useLastTwoWeeksData = () => {
    const { channels } = useSelector((state: any) => state.ServerSentiment);

    return useMemo(() => {
        const twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

        const filteredData = channels.flatMap((channel: { data: any[]; }) =>
            channel.data.filter((item: any) => new Date(item.Date) >= twoWeeksAgo)
        );

        const dailyData = filteredData.reduce((acc: any, item: any) => {
            const date = item.Date.split('T')[0];
            if (!acc[date]) {
                acc[date] = { total: 0, positive: 0, negative: 0 };
            }
            acc[date].total += item.Total_Positive + item.Total_Neutral + item.Total_Negative;
            acc[date].positive += item.Total_Positive;
            acc[date].negative += item.Total_Negative;
            return acc;
        }, {});

        const sortedDates = Object.keys(dailyData).sort().slice(-14);

        return {
            total: sortedDates.map(date => dailyData[date].total),
            positive: sortedDates.map(date => dailyData[date].positive),
            negative: sortedDates.map(date => dailyData[date].negative),
        };
    }, [channels]);
};

// Updated SparklineChart component
const SparklineChart = ({ dataKey }: { dataKey: 'total' | 'positive' | 'negative' }) => {
    const lastTwoWeeksData = useLastTwoWeeksData();
    const data = lastTwoWeeksData[dataKey];

    const options: ApexOptions = {
        chart: {
            type: dataKey === 'total' ? 'line' : 'area',
            sparkline: { enabled: true },
        },
        stroke: {
            curve: 'smooth' as const,
            width: 2,
        },
        colors: [dataKey === 'total' ? 'url(#totalGradient)' : dataKey === 'positive' ? '#3BCDC2' : '#D579EF'],
        tooltip: { enabled: false },
        fill: {
            type: dataKey === 'total' ? 'solid' : 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100]
            }
        },
    };

    return (
        <>
            {dataKey === 'total' && (
                <svg style={{ height: 0 }}>
                    <defs>
                        <linearGradient id="totalGradient" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="#5894FF" />
                            <stop offset="100%" stopColor="#ED346B" />
                        </linearGradient>
                    </defs>
                </svg>
            )}
            <ReactApexChart
                options={options}
                series={[{ data }]}
                type={dataKey === 'total' ? 'line' : 'area'}
                height={dataKey === 'total' ? 'auto' : 60}
                width={dataKey === 'total' ? "100%" : "50%"}
            />
        </>
    );
};

export {
    SparklineChart,
};