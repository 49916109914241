import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchServerSentimentIndividual } from "./thunk";

interface ServerSentimentIndividualItem {
    User: string;
    Date: string;
    Score: number;
}

interface ServerSentimentIndividualState {
    data: ServerSentimentIndividualItem[];
    loading: boolean;
    error: string | null;
}

const initialState: ServerSentimentIndividualState = {
    data: [],
    loading: false,
    error: null,
};

const serverSentimentIndividualSlice = createSlice({
    name: "serverSentimentIndividual",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServerSentimentIndividual.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                fetchServerSentimentIndividual.fulfilled,
                (state, action: PayloadAction<ServerSentimentIndividualItem[]>) => {
                    state.loading = false;
                    state.data = action.payload;
                }
            )
            .addCase(fetchServerSentimentIndividual.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "An error occurred";
            });
    },
});

export default serverSentimentIndividualSlice.reducer;