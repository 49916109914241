import { combineReducers } from "redux";
import LayoutReducer from "./layouts/reducer";
import LoginReducer from "./auth/login/reducer";
import RegisterReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";
import SentimentReducer from "./sentiment/reducer"
import ServerSentimentReducer from "./server_sentiment/reducer";
import ChannelWordcloudReducer from "./wordcloud/reducer";
import SubscriptionReducer from "./subscription/reducer";
import ChannelMetricReducer from "./metrics/reducer";
import UserSubscriptionReducer from "./user_subscription/reducer";
import ServerSentimentIndividiualReducer from "./server_sentiment_individual/reducer";
import ChannelSentimentIndividualReducer from "./channel_sentiment_individual/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Register: RegisterReducer,
    Profile: ProfileReducer,
    Sentiment: SentimentReducer,
    ServerSentiment: ServerSentimentReducer,
    ChannelWordcloud: ChannelWordcloudReducer,
    Subscription: SubscriptionReducer,
    ChannelMetric: ChannelMetricReducer,
    UserSubscription: UserSubscriptionReducer,
    ServerSentimentIndividual: ServerSentimentIndividiualReducer,
    ChannelSentimentIndividual: ChannelSentimentIndividualReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;